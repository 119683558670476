@import url(./_assets.scss);

//Color Scheme....
$primary_color: #006A5F;
$background_color: #FFFEF8;
$secondary_color: #707070;
$accemt_color: #f8f489;



body::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;

   


    &::-webkit-scrollbar {
        display: none;
    }

    //Main menu
    .main_menu {

        width: 100%;
        height: 100dvh;
        background-color: $primary_color;
        position: absolute;
        top: -100%;
        color: white;
        display: grid;
        place-items: center;
        z-index: 11;

        .close_menu {
            width: 40px;
            position: absolute;
            right: 5%;
            top: 5%;
            transform: translate(-5%, -5%);
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        .toggle_menu {
            width: min(60em, 60%);

            ul {
                list-style-type: none;
                padding-left: 0;
                font-size: clamp(2.5rem, 4vw, 3.5rem);
                font-family: marquisette;

                li {
                    cursor: pointer;
                    width: fit-content;
                    border-bottom: 1px white solid;

                    &:hover {
                        color: $accemt_color;
                    }
                }
            }

            .contact_info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    color: white;

                    &:hover {
                        color: $accemt_color;
                    }
                }

                .social_media {
                    width: 20%;

                    ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        li {
                            width: 30px;

                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                .menu_logo {
                    width: 60px;

                    img {
                        width: 100%;
                    }
                }
            }

        }
    }

    // Splash Screen styling here...
    .splash_screen {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        overflow: hidden;


        .splash_element {
            width: inherit;
            height: inherit;
            background-color: $primary_color;
            position: absolute;
            top: -100%;
            display: grid;
            place-items: center;

            img {
                width: min(150px, 15%);
                opacity: 0;
                transform: translateY(50px);
            }

        }
    }

    //Scroll watcher styling here...
    .scroll-watcher {
        height: 5px;
        position: fixed;
        top: 0;
        z-index: 1000;
        background-color: $primary_color;
        width: 100%;
        scale: 0 1;
        transform-origin: left;

        animation: scroll-watcher linear;
        animation-timeline: scroll();
    }

    .contact_overlay{
        width: 60%;
        height: 60dvh;
        background-image: linear-gradient(to top, black 10%, rgba(22, 22, 22, 0.918));
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 10;
        display: grid;
        place-items: center;
        transition: .5s ;
        opacity: 0;
        visibility: hidden;

        .close_button {
            width: max(2rem, 1%);
            position: absolute;
            top: 2%;
            right: 5%;
            transform: translate(-5%,-2%);
            padding-top: 50px;
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        .loader {
            width: 48px;
            height: 48px;
            border: 5px solid #FFF;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            }

        .contact_submit{
            width: min(800px,80%);
            height: 500px;
            background-color: white;
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 2;
            display: none;

            .img{
                width: 20%;
                height: inherit;
                background: url(../images/contact_banner.webp);
                background-size: cover;
                display: none;
            }

            .content{
                display: grid;
                place-items: center;
                width: 65%;
                margin: 0 50px;

                .content_container{
                    .flexbox{
                        display: flex;
                        align-items: center;

                        .check_mark{
                            width: 40px;
                            margin-right:10px;

                            img{
                                width: 100%;
                            }
                        }
                    }

                    h1{
                        font-family: marquisette;;
                        color: $primary_color;
                    }

                    h2{
                        font-size: clamp(.9rem,2vw,1.5rem);
                    }

                    h3{
                        font-weight: 300;
                    }

                    button{
                        padding: 15px 30px;
                        background: $primary_color;
                        border: 2px solid $primary_color;
                        font-weight: bold;
                        color: white;
                        margin-top: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    //Gallery styling here...
    .gallery {
        width: 100%;
        height: 100dvh;
        background-image: linear-gradient(to top, black 10%, rgba(22, 22, 22, 0.918));
        position: fixed;
        top: 0;
        z-index: 10;
        color: white;
        display: none;

        .close_button {
            width: max(2rem, 1%);
            position: absolute;
            right: 5%;
            transform: translate(-5%);
            padding-top: 50px;
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        .arrow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 200px;
            position: absolute;
            left: 50%;
            bottom: 5%;
            transform: translate(-50%, );

            .img_index {
                width: 100px;
                display: grid;
                place-items: center;
            }

            img {
                width: 30px;
                cursor: pointer;

                &:nth-child(3) {
                    transform: rotate(180deg);
                }
            }



        }

        .image_holder {
            width: 85%;
            height: 80dvh;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: grid;
            place-items: center;


            img {
                width: 100%;
                height: inherit;
                object-fit: contain;

            }
        }
    }

    //whatsapp styling here...
    .whatsapp{
        position: fixed;
        width: 100px;
        right: 3%;
        bottom: 5%;
        transform: translate(-3%,-5%);
        z-index: 9;
        cursor: pointer;
        transition: .5s;
        display: none;

        img{
            width: 60%;
        }

        span{
            position: absolute;
            width: max-content;
            right: 45%;
            top: 50%;
            transform: translate(-45%,-50%);
            background-color: white;
            padding: 10px;
            opacity: 0;
            transition: .5s;
        }

        &:hover span{
            opacity: 1;
        }
    }

    .privacy_policy{
        display: grid;
        place-items: center;
        background-image: linear-gradient(to top, black 10%, rgba(22, 22, 22, 0.918));
        width: 100%;
        height: 100dvh;
        position: fixed;
        z-index: 11;
        color: white;
        //display: none;

        .policy{
            width: 70%;
            line-height: 1.7;
            height: 80dvh;
            overflow-y: auto;
            padding: 50px;
        }

        .privacy_close_button{
            position: absolute;
            top: 5%;
            right: 5%;
            transform: translate(-5%,-5%);
            width: 40px;
            cursor: pointer;

            img{
                width: 100%;
            }
        }

    }

    .main_container {
        width: 100%;
        background-color: $background_color;

        .container {
            width: 96%;
            margin: auto;

            .nav_bar {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                justify-content: space-between;
                padding-top: 4rem;

                .logo {
                    opacity: 0;
                    transform: translateY(30px);
                }



                .site_name {
                    text-align: center;
                    opacity: 0;
                    transform: translateY(30px);

                    .site_name_text {
                        font-size: 1.5rem;
                        line-height: 70%;
                        color: $primary_color;
                        font-family: marquisette;
                    }
                }

                .menu {
                    opacity: 0;
                    transform: translateY(30px);
                    cursor: pointer;

                    .bar1 {
                        width: 35px;
                        height: 3px;
                        background-color: $primary_color;
                        margin: 6px 0;
                    }

                    .bar2 {
                        width: 35px;
                        height: 3px;
                        background-color: $primary_color;
                        margin: 6px 0;
                    }
                }
            }


            .header {

                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 70%;
                text-align: center;
                margin-top: 5rem;
                margin-bottom: 6rem;
                font-family: marquisette;
                color: $primary_color;

                .header_text1 {
                    font-size: clamp(2.5rem, 5vw, 5rem);
                    width: 100%;
                    height: max(100px, 15vh);
                    margin-bottom: 1rem;
                    line-height: 1;
                    position: relative;
                    //opacity: 0;

                    .header_line_one,
                    .header_line_two {
                        width: 100%;
                        opacity: 0;
                        left: 50%;
                        transform: translate(-50%, 30%);
                        position: absolute;
                    }
                }

                .header_text2 {
                    font-size: clamp(0.8rem, 2vw, 1.4rem);
                    transform: translateY(30px);
                    opacity: 0;
                }


            }

            .banner_container {
                width: 100%;
                height: 90dvh;
                overflow: hidden;
                opacity: 0;

                .banner {
                    width: inherit;
                    height: inherit;
                    background-image: url(https://images.unsplash.com/photo-1581596326467-be575967dbfa?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
                    background-repeat: no-repeat;
                    //background-size: 150%;
                    background-position: center;
                    background-attachment: fixed;
                    overflow: hidden;

                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: none;
                    }
                }
            }

            .caption {
                padding-top: 10rem;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 60%;
                text-align: center;
                opacity: 0;
                font-weight: 500;


                .caption_text {
                    width: min(55rem, 90%);
                    margin: 0 auto;
                    font-size: clamp(0.2rem, 5vw, 1.29rem);
                    line-height: 2;
                    margin-bottom: 4rem;
                    opacity: 0;
                    transform: translateY(50px);
                    transition: .8s ease-out;
                }

                .caption_button {
                    margin-bottom: 5rem;
                    width: 100%;


                    .svg_image {
                        position: relative;
                        transition: .5s;

                        svg {
                            width: min(600px, 70%);
                            height: auto;
                            stroke-dasharray: 1000;
                            stroke-dashoffset: 1000;
                            cursor: pointer;

                        }



                        .svg_text {
                            font-size: clamp(1.2rem, 1.7vw, 1.6rem);
                            font-family: marquisette;
                            color: $primary_color;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        &:hover {

                            transform: scale(1.1);
                        }
                    }


                }
            }


        }




        .corousel {
            width: 100%;
            height: 45vh;
            margin-top: 15rem;
            overflow: hidden;


            .corousel_trey {
                width: max-content;
                height: 45vh;
                display: flex;
                gap: .5rem;
                animation: scroll 95s linear infinite;
                -webkit-timing-function: cubic-bezier(0.42, 0, 0.58, 1.0);


                .corousel_img {
                    width: 500px;
                    height: inherit;
                    position: relative;
                    -webkit-transform: translate3d(0, 0, 0);
                    overflow: hidden;

                    img {
                        width: 115%;
                        height: inherit;
                        object-fit: cover;

                        pointer-events: none;
                    }

                    .overlay {
                        width: 100%;
                        height: inherit;
                        position: absolute;
                        top: 0;
                        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.644), rgba(0, 0, 0, 0.404));
                        opacity: 0;
                        transition: .5s;
                        cursor: pointer;
                        display: grid;
                        place-items: center;
                        z-index: 999;

                        .zoom_icon {
                            width: 60px;
                            height: 60px;
                            background-image: url(../images/zoom-in.png);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }


                    }

                    &:hover .overlay {
                        opacity: 1;
                    }

                }


            }



            .corousel_image1 {
                width: 25%;
                height: inherit;
                background-image: url(https://images.unsplash.com/photo-1665172650926-a8842203591d?q=80&w=2021&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 5px;
            }

            .corousel_image2 {
                width: 25%;
                background-image: url(https://images.unsplash.com/photo-1638158894759-47edf16f80a5?q=80&w=2261&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 5px;
                height: inherit;
            }

            .corousel_image3 {
                width: 25%;
                background-image: url(https://plus.unsplash.com/premium_photo-1673108852141-e8c3c22a4a22?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 5px;
                height: inherit;
            }

            .corousel_image4 {
                width: 25%;
                background-image: url(https://plus.unsplash.com/premium_photo-1669725687152-498e152687ed?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: inherit;
            }
        }

        .corousel[data-animated="true"] {
            overflow: hidden;
            -webkit-mask: linear-gradient(90deg,
                    transparent,
                    white 20%,
                    white 80% transparent);
            mask: linear-gradient(90deg, transparent, white 20%, white 80% transparent);
        }

        .corousel[data-animated="true"] .corousel_inner {
            width: max-content;
            flex-wrap: nowrap;
            animation: scrolling linear;
            animation-timeline: scroll(root);
        }

        @keyframes scrolling {
            to {
                transform: translate(calc(-50% - 0.5rem));
            }
        }


        .services {
            display: block;
            width: 90%;
            margin: auto;
            overflow: hidden;

            .services_header {
                display: block;
                align-items: center;
                font-size: clamp(2.5rem, 5vw, 4rem);
                margin-top: 8rem;
                margin-bottom: 8rem;

                p {
                    padding: 0;
                    margin: 0;
                    font-family: marquisette;
                    color: $primary_color;
                    text-align: center;
                }

                .services_text {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    width: 35%;
                    padding-top: 1rem;
                    font-size: clamp(0.8rem, 4vw, 1.2rem);
                    line-height: 1.5;
                    color: $secondary_color;
                }

            }

            .serve_trey{
                display: flex;
                width: 200%;
                //gap: 20px;
                transition: .5s ease-in-out;
                //transform: translateX(-50%);

                .serv_2 {
                    width: 100%;
                    height: max(600px,60vh);
                    //margin-bottom: 8rem;
                    display: flex;
    
    
                    .serv_flex1 {
                        width: 40%;
                        margin-right: 10px;
                        font-family: "Montserrat", sans-serif;
                        color: black;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
    
                        .service_list {
                            .text1 {
                                .mobile_view{
                                    font-size: .8rem;
                                    padding-top: 5px;
                                    font-weight: 100;
                                }
                                &:hover {
                                    color: $primary_color;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                }
                            }
                        }
    
                        .selected {
                            color: $primary_color;
                            font-family: marquisette;
    
                            .text1 {
                                font-size: clamp(1.3rem, 3vw, 2rem) !important;
    
                                
                            }
                        }
    
    
    
                        .service_list {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
    
    
    
                            // .name_text{
                            //     .text1{
                            //         width: 80%;
                            //         color: $primary_color;
                            //         font-family: marquisette;
                            //         font-size: clamp(1.3rem, 3vw, 2rem);
                            //         position: absolute;
                            //         top: 25%;
                            //         left: 15%;
                            //         transform: translate(15% -20%);                                                                                                                 
                            //     }
                            // }
    
    
    
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                //font-size: clamp(1rem, 1.5vw, 1.1rem) !important;
                                font-weight: 500;
                                line-height: 1.2;
    
                            }
    
    
    
                        }
    
    
    
                        .name {
                            display: flex;
                            align-items: center;
    
                            .name_text {
                                position: relative;
    
                                img {
                                    margin-left: 8px;
                                }
    
                                .text {
                                    width: 80%;
                                    color: $primary_color;
                                    font-family: marquisette;
                                    font-size: clamp(1.3rem, 3vw, 2rem);
                                    position: absolute;
                                    top: 25%;
                                    left: 15%;
                                    transform: translate(15% -20%);
                                }
                            }
    
                        }
    
    
                        .reserve {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
                        .transport {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
                        .tours {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
                        .photo_services {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
                        .spa {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
    
                        .dining {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
                        .attractions {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
                        .shopping {
                            display: flex;
                            align-items: center;
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
    
                        .conceirge {
                            display: flex;
                            align-items: center;
    
    
                            .check {
                                margin-right: 12px;
                                background-repeat: no-repeat;
                                width: 7%;
                            }
    
                            .text1 {
                                font-size: 1rem;
                            }
                        }
    
    
                    }
    
    
    
                    .serv_flex2 {
                        width: 35%;
                        background-image: url(../images/gallery/curated/curated_itenaries_1.webp);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: relative;
                        margin-right: 10px;
                        border-radius: 4px;
    
                        .gradient {
                            position: absolute;
                            bottom: 0;
                            height: 20vh;
                            background-image: linear-gradient(to top, $primary_color , rgba(36, 36, 36, 0.479), transparent 100%);
                            display: grid;
                            place-items: center;
    
    
                            .gradient_text {
    
                                width: 75%;
                                margin: auto;
                                color: white;
                                line-height: 1.5;
                                font-weight: 500;
                                font-size: .9rem;
    
    
                                h2 {
                                    margin: 5px 0;
                                    font-size: clamp(.8rem, 1vw, 1rem);
                                }
    
                                span {
                                    font-weight: 300;
                                }
                            }
                        }
                    }
    
                    .serv_flex3 {
                        width: 35%;
                        display: block;
                        border-radius: 5px;
    
                        .image1 {
                            width: 100%;
                            height: 29.5vh;
                            background-image: url(../images/gallery/curated/curated_itenaries_2.webp);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            border-radius: 3px;
                        }
    
                        .image2 {
                            margin-top: 5px;
                            width: 100%;
                            height: 30vh;
                            background-image: url(../images/gallery/gallery_set/gallery_4.webp);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            border-radius: 3px;
                        }
                    }
                }

                .mobile_serve_container{
                    width: 100%;
                    height: inherit;

                    .serve_img{
                        width: 100%;
                        height: 50%;
                        background-color: red;
                        background-size: cover;
                        background-position: center;
                    }

                    .serve_img_container2{
                        display: flex;
                        height: 50%;
                        gap: 10px;
                        padding-top: 10px;
                        box-sizing: border-box;

                        .serve_img1{
                            width: 50%;
                            height: 100%;
                            background-color: pink;
                            background-size: cover;
                            background-position: center;
                        }

                        .serve_img2{
                            width: 50%;
                            height: 100%;
                            background-color: magenta;
                            padding-top: 0;
                            background-size: cover;
                            background-position: center;
                        }
                        

                    }

                    .serve_img{
                        .service_overlay{
                            width: inherit;
                            height: 100%;
                            background-image: linear-gradient(to top,black,transparent);
                            position: relative;
                            color: white;
                            font-size: .8rem;

                            .back_arrow{
                                width: 10%;
                                padding: 10px 0 0 20px;

                                img{
                                    width: 100%;
                                }
                            }

                            .mobile_description{
                                background-color: transparent;
                                width: auto;
                                height: auto;
                                position: absolute;
                                bottom: 0;
                                padding: 20px;
                                line-height: 1.7;
                            }
                        }
                    }


                }

            }

            
        }



        .about_board {
            width: 100%;
            background-color: white;
            display: flex;
            justify-content: center;
            color: $primary_color;
            //margin-bottom: 5rem;
            padding: 100px 0px;
            

            .about {
                width: min(900px,60%);
                margin: 10px;
                display: block;

                .about_details {
                    width: 94%;

                    .about_us {

                        p {
                            font-family: marquisette;
                            font-size: clamp(4rem, 4vw, 5rem);
                            margin-bottom: 2.5rem;
                            margin-top: 0;
                        }

                        .about_text {
                            width: 90%;
                            line-height: 2;
                            color: black;
                            font-weight: 500;

                        }
                    }
                }

                .other_details {
                    display: flex;
                    justify-content: flex-start;


                    .mission {
                        width: 45%;

                        p {
                            font-family: marquisette;
                            font-size: clamp(1.9rem, 4vw, 2.5rem);
                            margin-bottom: 1rem;
                        }

                        .mission_text {
                            width: 80%;
                            line-height: 2;
                            color: black;
                            font-weight: 500;

                        }

                    }

                    .vision {
                        width: 45%;

                        p {
                            font-family: marquisette;
                            font-size: clamp(1.9rem, 4vw, 2.5rem);
                            margin-bottom: 1rem;
                        }

                        .vision_text {
                            width: 100%;
                            line-height: 2;
                            color: black;
                            font-weight: 500;

                        }

                    }
                }


            }


            .about_image {
                width: 23%;
                background-image: url(../images/logo.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

            }
        }


        .socials {
            margin-top: 7rem;
            margin-bottom: 6rem;

            p {
                font-family: marquisette;
                color: $primary_color;
                font-size: clamp(2rem, 4vw, 2.5rem);
                margin-bottom: 1rem;
                margin-left: auto;
                margin-right: auto;
                width: 70%;
                text-align: center;
            }
        }


        .socials_container {
            width: 100%;
            margin-bottom: 10rem;

            .image_tray {
                display: flex;
                width: 100%;
                height: 50vh;
                position: relative;


                .insta_logo {
                    width: 10%;
                    position: absolute;
                    top: 50%;
                    left: 27.5%;
                    transform: translate(-27.5%, -50%) scale(1);
                    cursor: pointer;
                    transition: .3s;

                    img {
                        width: 100%;

                    }

                    &:hover {
                        transform: translate(-27.5%, -50%) scale(1.1);
                    }
                }

                .tiktok_logo {
                    width: 15%;
                    position: absolute;
                    top: 50%;
                    right: 1%;
                    transform: translate(-1%, -50%) scale(1);
                    cursor: pointer;
                    transition: .3s;

                    img {
                        width: 100%;

                    }

                    &:hover {
                        transform: translate(-1%, -50%) scale(1.1);
                    }
                }

                .flex1 {
                    width: 30%;
                    background-image: url(../images/gallery/private_tour/private_tour_2.webp);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-right: 0.5rem;
                }

                .flex2 {
                    width: 20%;
                    margin-right: 0.5rem;

                    .flex2_image1 {
                        width: 100%;
                        height: 24.5vh;
                        background-image: url(../images/gallery/gallery_set/gallery_12.webp);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-bottom: 0.6rem;

                        .social_overlay {
                            img {
                                display: none;
                            }
                        }
                    }

                    .flex2_image2 {
                        height: 24.5vh;
                        background-image: url(../images/gallery/gallery_set/gallery_6.webp);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }

                .flex3 {
                    width: 30%;
                    margin-right: 0.5rem;
                    background-image: url(../images/gallery/curated/curated_itenaries_3.webp);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: relative;
                    display: grid;
                    place-items: center;

                    a {
                        width: 30%;

                        img {
                            width: 100%;
                            position: relative;
                            z-index: 2;
                            transition: .3s;
                            cursor: pointer;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }



                    .social_overlay {
                        width: 100%;
                        height: 100%;
                        background-color: $primary_color;
                        position: absolute;
                        top: 0;
                        mix-blend-mode: multiply;
                    }
                }

                .flex4 {
                    width: 20%;

                    .flex4_image3 {
                        width: 100%;
                        height: 24.5vh;
                        background-image: url(../images/gallery/gallery_set/gallery_5.webp);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-bottom: 0.6rem;
                    }

                    .flex4_image4 {
                        height: 24.5vh;
                        background-image: url(../images/gallery/gallery_set/gallery_13.webp);
                        background-position-y: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                }
            }
        }

        .contact_container {
            width: 80%;
            height: 70vh;
            margin: auto;
            margin-top: 10rem;
            margin-bottom: 10rem;
            color: $primary_color;
            position: relative;

            .form_details {
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translate(-50%, -30%);

                .form_text {
                    width: 100%;
                    font-family: marquisette;
                    font-size: clamp(1.5rem, 5vw, 2.9rem);
                    margin-bottom: 5rem;
                }
 
                .details { 
                    width: 100%;
                    margin-bottom: 3rem;

                    .initials {
                        display: flex;

                        .name_details {
                            width: 50%;

                            input {
                                font-size: 1rem;
                                border: none;
                                background-color: $background_color;
                                border-bottom: 1px solid $primary_color;
                                width: 90%;
                                padding-bottom: 0.7rem;
                                outline: none;
                            }

                            .name_space::placeholder {
                                color: $primary_color;

                            }

                        }

                        .email {
                            width: 50%;

                            input {
                                font-size: 1rem;
                                border: none;
                                border-bottom: 1px solid $primary_color;
                                width: 99%;
                                background-color: $background_color;
                                padding-bottom: 0.7rem;
                                outline: none;
                            }

                            .email_text::placeholder {
                                color: $primary_color;

                            }

                        }
                    }


                }


                .cont_method {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid $primary_color;
                    padding-bottom: 0.5rem;
                    margin-bottom: 2rem;
                    position: relative;

                    .dropdown_items {
                        position: absolute;
                        width: 100%;
                        background-color: white;
                        border: #006A5F 1px solid;
                        margin-top: 20px;
                        height: 0px;
                        overflow: hidden;
                        opacity: 0;
                        transition: .3s;
                        z-index: 1;
                        display: none;

                        ul {
                            list-style-type: none;
                            padding-left: 0;
                            padding: 20px;

                            li {
                                padding-top: 10px;
                                cursor: pointer;
                                transition: .3s;

                                &:nth-child(1) {
                                    border-bottom: $primary_color solid .01px;
                                    padding-bottom: 10px;
                                }

                                &:hover {
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                    .cont_text {
                        width: 90%;


                    }

                    .dropdown {
                        margin-right: 10px;
                        cursor: pointer;

                        img {
                            width: 30px;
                        }


                    }
                }

                .phone_number {
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    display: none;
                    gap: 30px;

                    .country_codes {
                        width: 220px;
                        border-bottom: $primary_color solid 1px;

                        .code_holder {
                            display: flex;
                            justify-content: space-between;

                            #code_holder{
                                font-size: .9rem;
                            }

                            .codes_arrow {
                                width: 20px;
                                cursor: pointer;

                                img {
                                    width: 100%;
                                }

                            }

                            
                        }

                       
                    }

                    input {
                        width: inherit;
                        box-sizing: border-box;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px $primary_color solid;
                        padding-bottom: 10px;
                        outline: none;

                        &::placeholder {
                            color: $primary_color;
                            font-size: 1rem;
                        }
                    }
                }


                .message {
                    width: 100%;
                    margin-bottom: 2rem;
                    padding-top: 20px;

                    .message_text {
                        color: $primary_color;
                        position: relative;
                        textarea {
                            font-family: "Montserrat", sans-serif;
                            width: 100%;
                            height: 300px;
                            border: none;
                            background-color: $background_color;
                            outline: none;
                            resize: none;
                            border-bottom: 1px solid $primary_color;
                        }

                        .list_code{
                            position: absolute;
                            top: 0;
                            background-color: white;
                            border: 1px solid $primary_color;
                            height: clamp(180px, 10vw, 250px);
                            overflow-y: auto;

                            .codes_parent{
                                display: flex;
                                gap: 5px;
                                align-items: center;
                                padding: 10px 20px;
                                margin: 7px;
                                border-bottom: 1px solid rgb(224, 224, 224);
                                cursor: pointer;

                                &:hover{
                                    background-color:#f8f489;
                                }
                            }
                        }
                    }
                }

                .submit_button {
                    position: relative;
                    width: 180px;

                    button{
                        background: transparent;
                        border: none;
                        color: $primary_color;
                    }

                    a {
                        text-decoration: none;
                        cursor: pointer;
                        border: none;
                        background-color: none;
                        color: $primary_color;
                    }

                    img {
                        width: 100%;
                    }

                    .submit_text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 1.2rem;
                        cursor: pointer;
                    }
                }


            }



        }

        .call_details {
            padding-top: 100px;
            width: 80%;
            margin: 0 auto;
            padding-bottom: 5rem;

            .contact_form {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;


                .contact_us {
                    width: 85%;
                    color: $primary_color;
                    font-size: clamp(2rem, 4vw, 3rem);
                    font-family: marquisette;
                }


            }

            .number {
                color: $secondary_color;
                font-size: clamp(1rem, 1.5vw, 1.5rem);
                margin-bottom: 1rem;
            }

            .other_info {
                display: flex;
                justify-content: space-between;
                color: $secondary_color;
                width: 100%;
                font-size: clamp(1rem, 1.3vw, 1.3rem);



                .info {
                    font-size: clamp(1rem, 1.3vw, 1.3rem);

                    a{
                        color: $secondary_color;

                        &:hover{
                            color: $primary_color;
                        }
                    }
                }

                .privacy_text{
                    cursor: pointer;
                    text-decoration: underline;
                    &:hover{
                        color: $primary_color;
                    }
                }
            }

            .up_button {
                width: 60px;
                cursor: pointer;
                visibility: hidden;
                opacity: 0;
                transition: .5s;

                img {
                    width: 100%;
                }
            }
        }

    }
}